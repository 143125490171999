"use strict";

// The functions in this file are responsible for handling training data on both the client and server side

const moment = require("moment");
const CommonUtils = require("../generic/common_utils");

/**
 * @enum {TrainingAction}
 */
const TRAINING_ACTIONS = {
  ASSIGNED: "Assigned",
  TRAINED: "Trained",
  REMOVED: "Removed",
  TRAINED_OUTSIDE: "TrainedOutside",
};

const INVALID_DATE = "Invalid date";

const TRAINING_STATUS = {
  COMPLETED: "Completed",
  OVERDUE: "Overdue",
  SUPERSEDED: "Superseded",
  PENDING: "Pending",
  TRAINED_OUTSIDE: "Not Tracked",
};

module.exports.INVALID_DATE = INVALID_DATE;
module.exports.TRAINING_STATUS = TRAINING_STATUS;

module.exports.ONBOARDING_STATUS = {
  DISABLE_NOTIFICATIONS: "Notifications Disabled",
  CONFIRM_ASSIGNMENT: "Assignment Confirmation",
  UPDATE_ALL: "All Training Updated",
  ENABLE_NOTIFICATIONS: "Notifications Enabled",
  NONE: "None",
};

/**
 * Gets an array or enumerable with training records and sorts them in the order to be processed
 * to retrieve information from them in training reports.
 * @param records
 * @return {*[]}
 */
function getSortedRecords(records) {
  return [...records].sort(CommonUtils.sortBy("trainingRecordId", "retrainingCount"));
}

/**
 * This function returns the latest version of each document
 * @param instances instances received from the server
 * @returns {any[]} documents latest version
 */
module.exports.getDocumentsLatestVersions = function (instances) {
  let documentToFinalVersion = exports.getDocumentsToLatestVersionsMap(instances);
  const sortedInstances = getSortedRecords(instances).map((instance) => {
    // console.log(instance);
    if (!instance.typeCode) {
      instance.typeCode = "DOC";
    }
    return instance;
  });

  return sortedInstances.filter((instance) => {
    let documentVersion = documentToFinalVersion.get(instance.documentId);
    return (
      CommonUtils.parseInt(instance.documentVersion) ===
      CommonUtils.parseInt(documentVersion.documentVersion)
    );
  });
};

/**
 * This function returns the latest version of each document
 * @param instances instances received from the server
 * @returns {any[]} documents latest version
 */
module.exports.getDocumentsLatestVersionsForMatrix = function (instances) {
  let documentToFinalVersion = exports.getDocumentsToLatestVersionsMap(instances);
  const sortedInstances = getSortedRecords(instances);

  const resultMap = new Map(
    sortedInstances
      .filter((instance) => {
        let documentVersion = documentToFinalVersion.get(instance.documentId);
        return (
          CommonUtils.parseInt(instance.documentVersion) ===
          CommonUtils.parseInt(documentVersion.documentVersion)
        );
      })
      .map((instance) => {
        const key = `${instance.userId}|${instance.documentId}`;
        return [key, instance];
      }),
  );

  const latestRetraining = sortedInstances.filter((instance) => {
    let documentVersion = documentToFinalVersion.get(instance.documentId);
    return (
      CommonUtils.parseInt(instance.documentVersion) ===
        CommonUtils.parseInt(documentVersion.documentVersion) &&
      CommonUtils.parseInt(instance.retrainingCount) ===
        CommonUtils.parseInt(documentVersion.retrainingCount)
    );
  });

  for (let instance of latestRetraining) {
    const key = `${instance.userId}|${instance.documentId}`;
    resultMap.set(key, instance);
  }
  return getSortedRecords(resultMap.values());
};

/**
 * This function returns a map between document and their latest version
 * @param instances instances received from the server
 * @returns {Map<any, any>} map between document and their latest version
 */
module.exports.getDocumentsToLatestVersionsMap = function (instances) {
  const documentToFinalVersion = new Map();

  // We need to ensure we get here the latest retraining of the latest version
  const sortedInstances = getSortedRecords(instances);
  for (let instance of sortedInstances) {
    const existingVersionEntry = documentToFinalVersion.get(instance.documentId);
    if (
      !existingVersionEntry ||
      CommonUtils.parseInt(existingVersionEntry.documentVersionId) <
        CommonUtils.parseInt(instance.documentVersionId) ||
      CommonUtils.parseInt(existingVersionEntry.retrainingCount || 0) <
        CommonUtils.parseInt(instance.retrainingCount || 0)
    ) {
      documentToFinalVersion.set(instance.documentId, instance);
    }
  }
  return documentToFinalVersion;
};

/**
 * Creates a default value for the completion status options.
 * @return {{reportDate: (*|moment.Moment), completedAsDate: boolean}}
 */
function getDefaultCompletionStatusOptions() {
  return { reportDate: moment(), completedAsDate: true, daysUntilOverdue: 7 };
}

/**
 * Gets the completion status given an {@link IAggregateTrainingRecord} object.
 * @param record {IAssignedDocumentVersion|IAggregateTrainingRecord|ITrainingRecord} The record to get the status for
 * @param allRecords {ITrainingRecord[]} All training records returned in that query
 * @param [options] The options used to retrieve the status.
 * @param [options.reportDate] {moment.Moment} The date to generate the data for (used in testing and reports)
 * @param [options.completedAsDate] {moment.Moment} If true, it shows the completed training as a date instead of a status.
 * @param [options.daysUntilOverdue] {number} The number of days until the assignment appear as overdue.
 * @param [options.reportDate] {moment.Moment} The date to generate the data for (used in testing and reports)
 * @param [options.completedAsDate] {moment.Moment} If true, it shows the completed training as a date instead of a status.
 * @param [options.daysUntilOverdue] {number} The number of days until the assignment appear as overdue.
 */
module.exports.getCompletionStatus = function (record, allRecords, options = undefined) {
  let { reportDate, completedAsDate, daysUntilOverdue } = {
    ...getDefaultCompletionStatusOptions(),
    ...(options || {}),
  };
  /**
   * Tries to create a standardized object from the received record, so it works
   * for many of the different queries we have in the system.
   * @type {IAggregateTrainingRecord}
   */
  let standardizedRecord = {
    ...record,
    /**
     * The action is the value in a single training record row.
     * The status is the calculated value from an aggregate record.
     * In this method, we will treat them as the same thing.
     */
    action: record.status || record.action || record.trainingRecordAction,
  };
  let isSuperseded = exports.isSuperseded(standardizedRecord, allRecords);

  let { effectiveDate, assignmentDate, completionDate, action } = standardizedRecord;

  if (record.hireDate && moment(record.hireDate).isAfter(moment(assignmentDate))) {
    assignmentDate = record.hireDate;
  }

  if (isSuperseded) {
    // if the record is superseded, that will override its status
    return TRAINING_STATUS.SUPERSEDED;
  } else if (action === TRAINING_ACTIONS.TRAINED_OUTSIDE) {
    // if the record has been trained outside QbDVision, we just assume it's correct
    return TRAINING_STATUS.TRAINED_OUTSIDE;
  } else if (
    action !== TRAINING_ACTIONS.ASSIGNED &&
    completionDate &&
    completionDate !== INVALID_DATE
  ) {
    // If the record not currently marked as assigned and the
    return completedAsDate ? completionDate : TRAINING_STATUS.COMPLETED;
  } else if (effectiveDate && effectiveDate !== INVALID_DATE) {
    if (moment(effectiveDate).isSameOrAfter(reportDate)) {
      return TRAINING_STATUS.PENDING;
    } else if (reportDate.isAfter(moment(assignmentDate).add(daysUntilOverdue, "days"))) {
      return TRAINING_STATUS.OVERDUE;
    } else {
      return TRAINING_STATUS.PENDING;
    }
  } else {
    if (reportDate.isAfter(moment(assignmentDate).add(daysUntilOverdue, "days"))) {
      return TRAINING_STATUS.OVERDUE;
    }
    return TRAINING_STATUS.PENDING;
  }
};

/**
 * Calculates whether or not the specified record is superseded
 * @param record {TrainingRecord|IAggregateTrainingRecord}
 * @param allRecords {(TrainingRecord|IAggregateTrainingRecord)[]}
 * @return {boolean|boolean|*}
 */
module.exports.isSuperseded = function (record, allRecords) {
  // No need to recalculate if the record already has this information
  if (typeof record.isSuperseded === "boolean") {
    return record.isSuperseded;
  }

  const moreRecentRecordsForThisDocumentAndUser = allRecords.filter((recordInArray) => {
    /*
       The queries that gets aggregate results from multiple records referring to the same
       document version will return different property names, so we handle both cases here.

       In order to make it more clear, let's organize this in a way that makes it clear what type
       of object we're handling:

       Variable = Property in single training records || Property in aggregate training records
      */
    const recordId = record.trainingRecordId || record.id;
    const userId = record.userId;
    const docId = record.trainingDocumentId || record.documentId;
    const docVersionId = record.trainingDocumentVersionId || record.documentVersionId;

    const otherUserId = recordInArray.userId;
    const otherRecordId = recordInArray.trainingRecordId || recordInArray.id;
    const otherAction = recordInArray.action || recordInArray.status;
    const otherDocId = recordInArray.trainingDocumentId || recordInArray.documentId;
    const otherDocVersionId =
      recordInArray.trainingDocumentVersionId || recordInArray.documentVersionId;

    /*
       Now, to find out what is superseded, we need to check whether some other record in the array
       that:
       1. Was created after the record we're evaluating
       2. Refers to the same document and user
       3. Refers to a later version of that document
       4. Is a record that is either pending or completed, which means it needs to be
          in some the following states:
            - Assigned
            - Trained
            - Trained Outside QbDVision
      */
    return (
      otherRecordId > recordId &&
      otherUserId === userId &&
      otherDocId === docId &&
      otherDocVersionId > docVersionId &&
      (otherAction === TRAINING_ACTIONS.ASSIGNED ||
        otherAction === TRAINING_ACTIONS.TRAINED ||
        otherAction === TRAINING_ACTIONS.TRAINED_OUTSIDE)
    );
  });

  /*
    So, if the record we're checking is still marked as Assigned, and there are more recent record,
    this mean the record is superseded.
   */
  return (
    record.action === TRAINING_ACTIONS.ASSIGNED &&
    moreRecentRecordsForThisDocumentAndUser.length > 0
  );
};

/**
 * Gets the assignment status given an {@link ITrainingRecord} object.
 * @param allRecords {ITrainingRecord[]}
 * @param record {ITrainingRecord}
 * @param [today] {moment.Moment} The current date (for testing)
 */
module.exports.getAssignmentStatusForTrainingRecord = function (record, allRecords = []) {
  allRecords = allRecords || [];

  let isSuperseded = exports.isSuperseded(record, allRecords);

  return isSuperseded ? TRAINING_STATUS.SUPERSEDED : record.action;
};

module.exports.isLatestRetraining = function (trainingRecords, trainingRecord) {
  return (
    !!trainingRecord &&
    !trainingRecords.find(
      (rec) =>
        rec.trainingDocumentId === trainingRecord.trainingDocumentId &&
        (rec.trainingDocumentVersionId > trainingRecord.trainingDocumentVersionId ||
          (rec.trainingDocumentVersionId === trainingRecord.trainingDocumentVersionId &&
            rec.retrainingCount > trainingRecord.retrainingCount)),
    )
  );
};
